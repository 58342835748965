.hp1 {
  display: flex;
  flex-direction: column;
}

.subcontainer1 {
  display: flex;
  justify-content: center;
  height: 40vh;
  width: 100vw;
  background-color: rgb(18, 17, 17);
}

.subcontainer2 {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  width: 100vw;
  background-color: #900048;
  flex-wrap: wrap;
}

.experiencedescription {
  width: 50vw;
  min-width: 50vw;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  justify-content: space-around;
}

.header {
  padding-left: 5%;
  padding-top: 5%;
  text-align: flex-start;
  text-overflow: clip;
  font-size: 50px;
}
.sticker {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -100px;
  align-items: center;
  justify-content: center;
  border: 1px transparent;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  background-color: palegoldenrod;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.textsticker {
  display: flex;
  font-size: 25px;
}

.textdescription:first-child::first-letter {
  color: palegoldenrod;
  font-weight: bold;
  font-size: 70px;
  float: left;
  line-height: 60px;
  padding-right: 8px;
}

.textdescription {
  font-size: 1.5vw;
}

.description {
  padding: 5%;
  display: flex;
  font-style: italic;
  font-size: 2vw;
  width: 100%;
  color: rgb(18, 17, 17);
  color: white;
  text-shadow: 0.07em 0 black, 0 0.07em black, -0.07em 0 black, 0 -0.07em black;
}
.icon {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 60px;
  cursor: pointer;
}

.arrowdown {
  cursor: pointer;
  position: relative;
  top: 0px;
  left: 100%;
}

.arrowdown path {
  box-shadow: 2px 3px 5px rgba(2, 20, 0, 1);
}

.arrowdown:hover {
  animation: arrow 700ms linear infinite !important;
}

@keyframes arrow {
  0% {
    top: 0px;
  }
  60% {
    top: 5px;
  }
  100% {
    bottom: 0px;
  }
}

.description2 {
  padding: 5%;
}

.scopributton {
  justify-content: center;
  align-items: flex-end;
  display: flex;
}
.textinizia {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1.5;
  background-color: #fff;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 20px;
  border: 1px solid #ced4da;
}

.scopri {
  border: none;
  padding-right: 0px;
  padding: 10px;
  background-color: transparent;
}

.inizia {
  font-size: 30px !important;
}
.specchiettotesi {
  position: relative;
  background-color: palegoldenrod;
  top: -300px;
  width: 40vw;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 12;
}

#menu {
  display: none;
  z-index: 2;
}

#menu-bar {
  width: 45px;
  height: 40px;
  margin: 30px 0 20px 20px;
  cursor: pointer;
}

.bar {
  height: 5px;
  width: 100%;
  background-color: #900048;
  display: block;
  border-radius: 5px;
  transition: 0.3s ease;
}

#bar1 {
  transform: translateY(-4px);
}

#bar3 {
  transform: translateY(4px);
}

.mynav {
  transition: 0.3s ease;
  display: none;
}

.mynav ul {
  padding: 0 22px;
}

.mynav li {
  list-style: none;
  padding: 12px 0;
}

.mynav li a {
  color: white;
  font-size: 20px;
  text-decoration: none;
}

.mynav li a:hover {
  font-weight: bold;
}

.menu-bg,
#menu {
  top: 0;
  left: 0;
  position: absolute;
}

.menu-bg {
  z-index: 1;
  width: 0;
  height: 0;
  margin: 30px 0 20px 20px;
  background: radial-gradient(circle, #900048, #900048);
  border-radius: 50%;
  transition: 0.3s ease;
}

.change {
  display: block;
}

.change .bar {
  background-color: white;
}

.change #bar1 {
  transform: translateY(4px) rotateZ(-45deg);
}

.change #bar2 {
  opacity: 0;
}

.change #bar3 {
  transform: translateY(-6px) rotateZ(45deg);
}

.change-bg {
  width: 520px;
  height: 460px;
  transform: translate(-60%, -30%);
}

@media (max-width: 768px) {
  .sticker {
    height: 100px;
    width: 100px;
    top: -50px;
  }

  .textsticker {
    font-size: 12px;
  }

  .description {
    display: none;
  }

  .specchiettotesi {
    top: 0px;
    width: 100%;
  }

  .titolohp1 {
    display: none;
  }

  .subcontainer2 {
    flex-wrap: nowrap;
  }

  .experiencedescription {
    width: 100%;
    min-width: 100% !important;
  }
}

.coltotale {
  position: relative;
  width: 340px !important;
  min-width: 200px;
  margin-left: 4em;
  background-color: transparent !important;
}

.customforminput {
display: block;
width:166px;
height: 28px;
border-radius: 8.8px;
padding-left: 1rem;
padding-right: 1rem;
margin-right: 1rem;
font-weight: 400;
line-height: 1.5;
background-color: #fff;
border: 1px solid #ced4da;
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
-webkit-appearance: none;
appearance: none;
}

.pricepag {
  font-size: 14px;
}

.flexible_end {
  display: flex;
  width: auto;
  justify-content: flex-start;
  align-items: center;
}

.title {
  text-align: center;
  width: 100%;
  background: white;
  color: black !important;
  padding: 20px;
  font-size: 2.5vw;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.selezioni {
  padding: 10px;
  padding-bottom: 20px;
}

.sel {
  display: block;
}

.coltotale {
  padding: 0 !important;
  z-index: 1;
}

.blabla {
  display: flex;
}

li:last-child {
  border-bottom: none;
}
@media screen and (max-width: 480px) {

  .customforminput {
    width: 100px;
    
  }

  .coltotale {
    height: fit-content;
    margin-left: 0px;
  }

  .blabla {
    justify-content: center;
    flex-wrap: wrap;
  }
}

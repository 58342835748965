.containerfaq {

    background: white !important;
    height: fit-content;
}

.accordion-button:not(.collapsed) {
    color: var(--primary-color) !important;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
}

.accordion-button:focus {
    z-index: 3;
    border-color: white !important;
    outline: 0;
    box-shadow: 0 ;
}

.accordion-item {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom : 1px solid rgba(0,0,0,.125);

}
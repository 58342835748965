input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  
  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 0%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  input[type="radio"]:hover {
    cursor: pointer;
  }

.customheader {

font-size: 35px;
font-weight: bold;

}

.customtitle {

    font-size: 2.5rem;
    font-weight: bold;
    color: #b01b40;
    
    }

.mappa {
    z-index: 1;
    width: 1200px;
    padding: 10px;
    margin-top: 50px;
    height: 40%;
    margin-bottom: 46px;
}

.mappa > iframe {
    height: 330px !important;
    width: 1200px !important;
}

.customheader3 {

    font-size: 25px;
    font-weight: bold;
    color: #b01b40;
    
    }

 #background-video {
    display: none;
    z-index: 0;
    height: 100vh;
    width: 100vw;
    filter: blur(2px);
    object-fit:cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
 }   

.customsubtitle {
 font-size: 18px;
 font-weight: bold;
 color: #b01b40;   
}

.containercontattaci {

    width: 100%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.evenly {
    justify-content: space-evenly
}
.cont_orari {
    width: 100%;
    text-align: center;
}

.text-left {
    border-bottom: 0.5px solid;
}

.orari {
    display: flex;
    justify-content: space-between;
}

.container_orari {
    width: auto;
}

.containercontattaci2 {

   box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
   background: #F6F6F5;
   display: flex;
   justify-content: space-around;
   z-index: 1; 
   width: 1200px;
   margin-left: 5em;
   margin-right: 5em;
   height: 90%;
}

.containercontattaci2:hover > #background-video {
    filter: blur(2px);
}

.custombody {
font-size: 18px;
}

.desc_contatti_item {
font-size:16px;
margin-left: 18px;
}

.bold {
    font-weight: bold;
}

.contatti_item {
    display: flex;
    width: 250px;
    align-items: center;
    margin-bottom: 30px;
}

.icona {
    height: 38px;
    width:38px;
}
.containercontatti {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: white !important;
    align-items: center;
}

.containercontattititle {
    width: 100%;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
    background-color: #fff;
    z-index: 1;    
}

.divider {
    height: 77%;
    color: #b01b40;
    border: 1.2px solid;
    align-self: center;
}

.specchiettoconfig {
    background: #F6F6F5;
    padding:30px;
    border-radius: 10px;
    width: 60%;
    overflow: auto;
    margin-bottom: 1em;
}

.whatsappimg {
    height: 60px;
    width: 60px;
    border: none;
    background-size: cover !important;
    background: url("/public/whatsapp.png");
}

.whatsappimg2 {
    height: 30px;
    width: 30px;
    border: none;
    background-size: cover !important;
    background: url("/public/whatsapp.png");
}

.whatsappdiv {

    border: 0px;
    background-color: transparent;

}

.whatsappdiv:hover {
    cursor:pointer;

    transition: all 0.2s;
}

.whatsappdiv:hover ~ .mapmessage {
    display: inline;
}

.contattitelefonici {
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width:100%;
    height: 80%;
    padding:30px;
}

.trovaci {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    padding:30px;
}

.richiestainviata {

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;

}
.mapimg {
    height: 30px;
    width: 30px;
    max-width:100%;
    border: 2px solid #fff;
    border-radius: 10px;
}

.mapimg:hover {
    cursor: pointer;
    filter: opacity(0.5);
}

.mapmessage {
    position: absolute;
    top: 50%;
    z-index: 2;
    display: none;
}


.secondcontatti {
    width: 50%;
    margin-left: 50px;
    padding: 20px;
}
.inputcontatti {
    display: flex;
    margin-bottom: 1em;
    font-size: 15px;
}


.dovesiamo {
    display: flex; 
    flex-direction: column; 
    align-items: center;
    width: 70%
}

@media only screen and (max-width: 1100px) {

    .dovesiamo {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {

    .whatsappdiv {
        width:max-content;
    }
    
    .textwhatsapp {
        display: none;
    }


    .inputcontatti {
        font-size: 10px;
    }
    
    .textmap {
        font-size: 10px;
    }

    .dovesiamo {
        width: 100%;
    }

    .customheader3 {
        font-size: 18px;
    }
    
    .customsubtitle {
        font-size:15px;
    }
    }

@media only screen and (max-width: 480px) {

    .containercontattaci2 {
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 2em;
        margin-right: 2em;
    }

    .privacyspan {
        font-size:10px;
    }

    .formfield {
        font-size: 10px;
    }

    .secondcontatti {
        margin-left: 0px;
        width:100%;
        padding: 15px;
    }

    .specchiettoconfig {
        width: auto;
    }

.whatsappdiv {
    width:max-content;
}

.textwhatsapp {
    display: none;
}

.textmap {
    font-size: 10px;
}

.inputcontatti {
    font-size: 8px;
}

.button {
    font-size: 14px;
}

.btninput {
    font-size: 14px;
}

.customheader3 {
    font-size: 16px;
}

.customsubtitle {
    font-size:13px;
}
}
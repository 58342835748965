.circle {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet
  );
  z-index: 1;
  cursor: pointer;
}

.circlelittle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet
  );
  z-index: 1;
}

.Pagediv:hover .divcolor {
  display: inline-block;
  opacity: 1;
  background-color: white;
}

.Pagediv:hover .page {
  opacity: 0.2;
  filter: grayscale(1);
  background-color: black;
  cursor: pointer;
}

.Pagediv:hover .pagebn {
  opacity: 0.2;
  filter: grayscale(0);
  background-color: black;
  cursor: pointer;
}

.divcolorinactive {
  display: none;
}

.divcolor {
  display: none;
  border: 1px solid;
  border-radius: 50px;
  align-self: center;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 50%;
  margin-left: auto;
  right: 0;
  padding: 10px;
  font-size: 15px;
  cursor: pointer;
}

.divcolor:hover {
  background-color: rgba(255, 255, 255, 0.584);
  color: #000;
}

.circlebn {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: linear-gradient(
    79deg,
    rgba(2, 0, 36, 1) 43%,
    rgba(255, 255, 255, 1) 54%
  );
  z-index: 1;
  cursor: pointer;
}

.littlecircle {
  height: 15px;
  width: 15px;
  cursor: auto;
}

#page {
  filter: grayscale(0);
}

.Pagediv {
  position: relative;
  height: 100%;
}

.badgePages {
  position: absolute;
  display: flex;
  padding: 5px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  height: 20px;
  bottom:0px;
  right:5px;
  text-align: center;
  z-index: 1;
  background-color: white;
  border-radius: 20px;
  border: 1px solid;
  margin-bottom: 0.1em;

}

@media (max-width: 768px) {
  .divcolor {
    padding: 5px;
    font-size: 10px;
  }
}

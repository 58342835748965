.el2.dati {
  width: 100%;
  padding: 1rem;
  padding-top: 0px;
}

.formfield {
  position: relative;
  width: 100%;
  padding: 10px;
  font-size: 14px;
}

.forminput:focus ~ .form__label {
  top: 10px;
  color: #b01b40;
  font-weight: bold;
  transition: all 0.2s;
  font-size: 8px;
}
.forminput:focus {
  border-width: 2px;
  border-color: #b01b40;
}

.forminput {
  width: 100%;
  border: 0;
  border-bottom: 1px solid;
  border-color: #adabab;
  outline: 0;
}

.forminput.contatti {
  width: 100%;
  border: 0;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid;
  border-color: black;
  outline: 0;
}

.forminput2 {
  width: 100%;
  resize: none;
  padding: 4px;
  border: 1px solid;
  border-radius: 8px;
  border-color: black;
}

.forminput.textarea {
 
  resize: none;
}

.form__label {
  position: absolute;
  top: 26px;
  display: block;
  transition: all 0.2s;
  pointer-events: none;
}

.form__label.contatti {
  position: absolute;
  top: 8px;
  display: block;
  transition: all 0.2s;
  pointer-events: none;
}

.form__label:visited {
  top: 0px;
  color: #b01b40;
  font-weight: bold;
  transition: all 0.2s;
  font-size: 8px;
}

.iscomplete {
  top: 10px;
  font-weight: bold;
  transition: all 0.2s;
  font-size: 10px;
}

.consegna {
  justify-content: flex-start;
  width: fit-content;
  border-radius: 10px;
  display: flex;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 8px 1px;
}

.itemconsegna {
  padding: 8px;
  background: #fff;
  cursor: pointer;
}

.itemconsegna.casa {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.itemconsegna.negozio {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.itemconsegna.active {
  background-color: #b01b40;
  color: white;
}

.address {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.title2 {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  padding-bottom: 0px;
  margin-top: 10px;
}
.divaddress {
  position: relative;
  width: 45%;
  flex-shrink: 1;
}

.inputindirizzo {
  width: 100%;
}
.divncivico {
  margin-right: auto;
  margin-left: 10px;
  width: 10%;
}

.inputncivico {
  width: 100%;
}
.divCAP {
  width: 25%;
}

.note {
}
.città {
  width: 50%;
}
.provincia {
  width: 25%;
}

.inputCAP {
  width: 100%;
}
label {
  padding-bottom: 0.5em;
}

.location-search-input {
  width: 100%;
}

.autocomplete-dopdown-container {
  color: black;
}

.ritiro {
  padding: 0.5em;
}

.customlink {
  text-decoration: none;
  color: var(--primary-color); 
  font-size: 14px;
}

.customlink:hover {
  color: white;
}
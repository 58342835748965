.divriepilogo {
  margin-right: 0.1rem;
  width: 50vw;
  display: flex;
  flex-direction: column;
  padding: 30px;
  overflow: scroll;
  color: black;
  font-size: 14px;
}

.paypal-buttons {
  position: relative;
  z-index: 0;
}
.divpagamento {
  padding: 50px;
  display: flex;
  flex-direction: column;
  width: 30vw;
  height: 100%;
  overflow: scroll;
  color: black;
}

.riepilogo {
  display: flex;
  width: 70vw;
}

.paypal-buttons {
  min-width: 100px !important;
}

.tableordini {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #6f6f6f40;
}

.bordergray {
  border-bottom: 1px solid #6f6f6f40
}

.left-auto {
  margin-left:auto;
}

.ordine {
  border: 0;
  margin-bottom: 11px;
  margin-top: 10px;
  border-top: none !important;
  padding-bottom: 10px;
}

.last {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  border-bottom: none;
}

.borderbottom {
  border-bottom: 1px solid #6f6f6f40
}

.noborderbottom {
  border-bottom: none;
}

.hidden {
  visibility: hidden;
}


@media only screen and (max-width: 480px) {

  .divpagamento {
    width: 100%;
  }

  .divriepilogo {
    width: 100%;
  }

  .riepilogo {
    width: 100%;
  }
  
}

.document {
  display: grid;
  grid-template-columns: repeat(5, 19%);
  grid-template-rows: repeat(20%);
  grid-gap: 5px;
  overflow: scroll;
}

.imgcontainer {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid rgb(202, 202, 202);
  box-shadow: 0px 4.4049248695373535px 22.02462387084961px 0px rgba(0, 0, 0, 0.07);

}

.boxpagine {
  align-self: center;
  background: lightgrey;
  padding: 3%;
  margin-bottom: 15px;
  padding-bottom: 1%;
  padding-top: 1%;
  font-size: 14px;
}

.textloading {
  padding: 50px;
  margin-top: auto;
  font-size: 35px;
  animation: loading 1s ease-in infinite;
}

.divbottoni {
  display: flex;
  margin-top:2%;
  justify-content:space-between;
}

@keyframes loading {
  0% {opacity: 0}
  25% {opacity: 0.5}
  50% {opacity: 1}
  75% {opacity: 0.5}
  100% {opacity: 0}
}
.circleprogressinactive {
  display: none;
}
.circleprogressbar {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.documentinactive {
  display: none;
}

.document .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page__textContent {
  display: none !important;
}

.react-pdf__Page__annotations {
  display: none !important;
}

.btnbn {
  background: linear-gradient(
    190deg,
    rgba(2, 0, 36, 1),
    rgba(255, 255, 255, 1)
  );
}

.spancolor {
  opacity: 1;
  display: inline-block;
  background: #191919;
  color: white;
  text-transform: uppercase;
  border-radius: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  font-size: 12px;
  padding: 0.5em;
}
.divcolori {
  padding: 0.5em;
}

.rowcolorinactive {
  display: none !important;
}

.rowcolori {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.rowpagine {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.btncolori {
  padding: 3px !important;
  background: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet
  );
  background-size: 200% 200%;
  position: relative;
  display: inline-block;
  border-radius: 7px;
  border: none !important;
}

.btncolori:hover {
  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
}

.btnbn {
  padding: 3px !important;

  background: linear-gradient(175deg, #fafafa, #000000);
  background-size: 400% 400%;
  position: relative;
  display: inline-block;
  border-radius: 7px;
  border: none !important;
}

.btnbn:hover {
  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
}
.el2.pagine {
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  height: 78vh;
}
.el3.totale {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 10vw;
}

.sceltacolore {
  border-width: 0px !important;
}

.divcontcolore {
  width: 63vw;
}


@-webkit-keyframes AnimationName {
  0% {
    background-position: 92% 0%;
  }
  50% {
    background-position: 9% 100%;
  }
  100% {
    background-position: 92% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 92% 0%;
  }
  50% {
    background-position: 9% 100%;
  }
  100% {
    background-position: 92% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 92% 0%;
  }
  50% {
    background-position: 9% 100%;
  }
  100% {
    background-position: 92% 0%;
  }
}

@media screen and (max-width: 768px) {
  .document {
    grid-template-columns: repeat(2, 50%);
  }
}

@media screen and (max-width: 480px) {

  .divcontcolore {
    width: 100%;
  }
}

.containerservizi {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: rgba(249, 247, 247, 1);
}
.servizigrid {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, 298px);
    gap: 14px;
}

.paypal-badges {
    margin-top: 50px;
}

.trustpilot-badges {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 229px;
    margin-top: 1em;
}

.container_servizi {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 50px;
    margin-bottom: 4em;
}

.imgservizi {
    height: 442px;
}

.servbtn {
    width: 220px;
}

.cont_servizio {
    height: 298px;
    width:298px;
    font-size: 20px;
}

.imgservizio {
padding-top: 12px;
height: 222px;
width: 268px;
}

.mb-none {
    margin-bottom: none !important;
}

.mt-40 {
    margin-top:40px !important;
}

.scrittaservizio {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
}

.quadri .imgservizio {
    object-fit: contain;
}

.cont_imgservizio {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    height: 100%;
    color: black !important;   
    border: 1px solid #D1D1D1;
}


.container_altriservizi {
    display: flex;
    flex-direction: column;
    width: 434px;
    height:100%;
    justify-content: center;
    margin-left: 25px;
}

@media only screen and (max-width: 480px) {

.imgservizi {
    height:200px;
    width: 100%;
}

.imgservizio {
    height:190px;
    width: 190px;
}

.cont_servizio {
    height: 230px;
    width: 230px;
    font-size: 17px;
}

.container_altriservizi {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 25px;
    width: 100%;
    margin-left: 0px;
}

.container_servizi {
    margin-bottom: 0px;
    flex-direction: column;
}

.mtm-mobile {
    margin-top: 14px;
}


.card {
    
}

}
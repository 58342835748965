.totcont {
  font-size: 16px;
  color: #fff;
  background: #00000063;
  position: sticky;
  width: inherit;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8%;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
  border-radius: 10px;
}
.totprice {
  display: flex;
  justify-content: space-between;
  font-size: 19px;
  font-weight: bold;
  padding: 10px;
}
.totale {
  margin-left: 2rem;
  margin-right: 1rem;
}

.titletotale {
  padding: 5%;
  text-align: center;
}

.ordini {
  border-bottom-width: 0px !important;
}

.pages {
  display: flex;
  padding: 10px;
}

.frecciatot {
  display: none;
  cursor: pointer;
}

@media (max-width: 576px) {

  .title-large {
    font-size:25px;
  }

  .totcont {
    font-size:12px;
  }

  .totprice {
    font-size: 14px;
  }
}


@media (max-width: 768px) {

  .totale {
  
  }

  .divpages {
    position: absolute;
    bottom: 5vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    left: 0;
  }

  .divpagesout {
    display: none;
  }

  .frecciatot {
    display: inline;
  }
}

.banner {
    height: 42px;
    display: flex;
    background: rgba(255, 255, 255, 0.9);
    margin-left: 60px;
    margin-right: 80px;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
}

.banneravvisi {
    height: 42px;
    display: flex;
    background: lightgray;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    line-height: normal;
}

.contentavvisi {

}

.banner .content {
    display: flex;
    width: 100%;
    min-width: 1200px;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.banner .left {
    position: relative;
    display: flex;
}

.banner .right {
    position: relative;
    display: flex;
    align-items: center;
}


.banner_icon {
    width: 15px;
    height: 15px;
}

.truck {
    width:25px;
    height: 25px;
}

.banner_icon .svg {
    display: block;
}

.margin_banner {
    margin-right: 8px;
}

@media screen and (max-width: 1000px) {

    .banner {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .banneravvisi {
        font-size: 11px;
    }
}
.contsel {
  overflow: hidden;
  font-weight: bold;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: white;
  font-size: 15px;
}

.btnsel {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
}

.selmenu {
  font-size: 15px;
  display: flex;
  justify-content: flex-start;
  padding: 0px !important;
  overflow: hidden;
  background-color: var(--secondary-color);
}


.selmenu.borderless {
  border-right: none;
  background: white;
  color: #b01b40;
}

.selmenu-item {
  width:100%;
  text-decoration: none;
  pointer-events: none;
  display: flex;
  align-items: baseline;
  color: rgb(42, 44, 44);
  padding:20px;
}

.selmenu-item.clickable {
  color: #b01b40;
  cursor: pointer;
  pointer-events: auto;
}

.selmenu-item.clickable:hover {
  background: var(--secondary-color-hover);
  cursor: pointer;
}

.item-inactive {
  margin-left: 1em;
  display: flex;
  font-size: 14px;
}

.item-active {
  color: var(--primary-color);
  text-transform: uppercase;
  margin-left: 1em;
  display: flex;
}

.selpagine {
  display: block;
  width: auto;
  -webkit-transition: all 0.4s ease 0.15s;
  -moz-transition: all 0.4s ease 0.15s;
  -o-transition: all 0.4s ease 0.15s;
  -ms-transition: all 0.4s ease 0.15s;
  transition: all 0.4s ease 0.15s;
}

.selpagineinactive {
  -webkit-transition: all 0.5s ease 0.15s;
  -moz-transition: all 0.5s ease 0.15s;
  -o-transition: all 0.5s ease 0.15s;
  -ms-transition: all 0.5s ease 0.15s;
  transition: all 0.5s ease 0.15s;
  height: 0vh;
  width: 0px;
  opacity: 0;
  overflow: hidden;
}

.selrilegature {
  display: block;
  -webkit-transition: all 0.4s ease-in-out 0.15s;
  -moz-transition: all 0.4s ease-in-out 0.15s;
  -o-transition: all 0.4s ease-in-out 0.15s;
  -ms-transition: all 0.4s ease-in-out 0.15s;
  transition: all 0.4s ease-in-out 0.15s;
}

.selrilegatureinactive {
  -webkit-transition: all 0.1s ease 0.15s;
  -moz-transition: all 0.1s ease 0.15s;
  -o-transition: all 0.1s ease 0.15s;
  -ms-transition: all 0.1s ease 0.15s;
  transition: all 0.1s ease 0.15s;
  height: 0px;
  width: 0px;
  opacity: 0;
  overflow: hidden;
}

.selspedizione {
  display: flex;
  -webkit-transition: all 0.3s ease 0.15s;
  -moz-transition: all 0.3s ease 0.15s;
  -o-transition: all 0.3s ease 0.15s;
  -ms-transition: all 0.3s ease 0.15s;
  transition: all 0.3s ease 0.15s;
}

.selspedizioneinactive {
  height: 0;
  opacity: 0;
  width: 0px;
  overflow: hidden;
}

.seldati {
  display: flex;
  -webkit-transition: all 0.3s ease 0.15s;
  -moz-transition: all 0.3s ease 0.15s;
  -o-transition: all 0.3s ease 0.15s;
  -ms-transition: all 0.3s ease 0.15s;
  transition: all 0.3s ease 0.15s;
}

.seldatinactive {
  height: 0;
  opacity: 0;
  width: 0px;
  overflow: hidden;
}

.freccia {
  color: #fff;
}

.last {
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .contsel {
    overflow: hidden;
    font-size: 12px;
  }
}

@media screen and (max-width: 620px) {
  .hidedescription {
    display: none;
  }

  .containerrow {
    margin-left: 20px;
  }
  }

@media screen and (max-width: 480px) {

  .el1 {
    width: 100%;
  }
  .contsel {
    width: auto;
    flex-direction: row;
  }

  .row_dropdown {
    flex: 1;
  }

  .contsel .row {
    --bs-gutter-x: 0 !important;
  }

  .selmenu {

    width: 100%;
  }
}

.of-contain {
    object-fit: contain;
}

.card_service {
    border: 0px;
    margin-bottom: 3em;
    min-width: 700px;
}

.card_servizio {
    height: 398px;
    width:414px;
}

.card {
    padding-left: 48px !important;
    padding-top: 58px !important;
    height: 100% !important;
    margin-bottom: 3em;
}

.card-body {
    padding-left: 32px !important;
}

.card-img-to {
    object-fit: cover;
    height: 398px;
    width:414px;
}

.card_border {
    border-bottom: 1px solid rgba(209, 209, 209, 1);
}


@media only screen and (max-width: 480px) {

.card {
    padding-left: 20px !important;
    padding-top: 30px;
}

.card_servizio {
    height: 150px;
    width:100%;
}

.card-img-to {
    height: 100%;
    width:100%
}
.card-body {
    padding-left:0px !important;
}

.flex-wrap-mobile {
    flex-wrap: wrap;
}

}
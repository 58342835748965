:root {
  --transition-duration: 0.2s;
  --transition-delay: 0.2s;
}

.navbar1 {
  background: #b01b40;
  display: flex;
  height: 60px;
  flex-wrap: wrap;
  font-size: 15px;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
}

.navbar1:hover .nav-links {
  color: grey !important;
  cursor: pointer;
}
.navbar1:hover .nav-links-active {
  color: rgb(255, 255, 255) !important;
}

.navbar1 .nav-links:hover {
  color: white !important;
}

.navbar-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
}

.navbar-logo {
  color: #fff;
  justify-self: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.dropdowntesi {
  z-index: 2;
  position: absolute;
  top: 105%;
  left: 5%;
  width: 200px;
  padding: 0.5rem;
  background: #b01b40;
  text-align: start;
  border-radius: 10px;
  transition: all 0.5 ease-in-out;
}

.dropdowntesiinactive {
  display: none;
  z-index: 3;
}

.dropdowntesilinks {
  width: 100%;
  list-style: none;
  padding: 10px;
  transition: all 0.5 ease-in-out;
}

.dropdowntesilinks a {
  text-decoration: none;
  outline: none;
  list-style-type: none;
  color: grey;
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}

.dropdowntesilinks a:hover {
  color: white;
}
.nav-menu .logo-item {
  left: -124px;
  justify-content: flex-start;
}

.nav-menu .contatti {
  width: fit-content !important;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: end;
  justify-content: space-between;
  margin-right: 60px;
  margin-left: 60px;
  width: 100vw;
  height: 100%;
  margin-top: auto;
  padding-left: 0;
}

.nav-item {
  position: relative;
  display: flex;
  text-align: start;
  outline: none;
  font-family: "Lato", sans-serif;
  font-family: "Oswald", sans-serif;
  justify-content: center;
  align-items: center;
  max-height: 98%;
  font-weight: 600;
  z-index: 1;
  font-size: 13px;
  text-transform: uppercase;
  white-space: nowrap;
  width: auto;
}

.nav-item .noselect {
  position: relative;
  z-index: 3;
}

.nav-item-active {
  position: relative;
  display: flex;
  outline: none;
  font-family: "Lato", sans-serif;
  font-family: "Oswald", sans-serif;
  justify-content: center;
  padding-top: 10px;
  font-weight: 600;
  background: linear-gradient(180deg, #000000 80%, #900048 20%);
  z-index: 1;
}
.nav-item:hover .nav-item:not(:hover) {
  color: rgb(129, 126, 126) !important;

}

.nav-item:focus .nav-item:not(:focus) {
  color: rgb(129, 126, 126) !important;
}

.first-nav-items {
  display: flex;
}

.nav-links {
  color: #fff;
  display: flex;
  border: none;
  align-items: center;
  text-decoration: none;
  padding: 12px;
  height: 80%;
  width: fit-content;
  justify-content: center;
  outline: none;
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;
}

.logomobile {
  display:none;
}


.nav-links-inactive {
  color: rgb(129, 126, 126) !important;
}

.last-nav-links {
  display: flex;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 70%;
  background: #fff;
  justify-content: center;
  outline: none;
  color: #b01b40;
  transition: all 0.2s;
}

.last-nav-links:hover {
  color: #fff;
  border: 1px solid #fff;
  background-color: #b01b40;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 90px;
  }
  100% {
    background-position: 140px;
  }
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.box {
  display: none;
}

.divlogoimg {
  display: flex;
  height: 50px;
}


@media screen and (max-width: 480px) {

.dropdowntesi {
  background: transparent;
}

.logomobile {
  position: absolute;
  display: flex;
  height: 50px;
  left: 65px;
}

.divlogoimg {
  height: 43px;
}

}

@media screen and (max-width: 1272px) {

 .nav-item {
  font-size: 12px;
 }

 .nav-item.logo-item {
  left: 0px !important;
}
}

@media only screen and (max-width: 1185px) {


  
  }

@media screen and (max-width: 1000px) {


  .nav-menu {
    margin-left: none;
  }

  .nav-menu .contatti {
    width: auto !important;
  }

  .first-nav-items {
    flex-direction: column;
    font-size: 20px;
    text-transform: uppercase;
  }

  .navbar-container .hidemobile {
    display: none;
  }

  .navbar-container .showmobile {
    display: flex;
  }

  .showsmallscreen {
    display: flex;
  }

  .nav-item-active {
    width: 100%;
  }

  .nav-item.logo-item {
    position: absolute;
    top: 15px;
    left: 0px;
    justify-content: center;
    margin-left: 0px;
    color: #fff;
  }


  .NavbarItems {
    position: relative;
  }

  .box {
    display: flex;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    right: 5%;
    box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.3);
    z-index: 4;
  }

  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    width: 80px;
    cursor: pointer;
  }

  span.btnanimation {
    display: block;
    width: 100%;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    height: 8px;
    background: #fff;
    transition: all 0.3s;
    position: relative;
  }

  span.btnanimation + span.btnanimation {
    margin-top: 14px;
  }

  .active span.btnanimation:nth-child(1) {
    animation: ease 0.7s top forwards;
  }

  .not-active span.btnanimation:nth-child(1) {
    animation: ease 0.7s top-2 forwards;
  }

  .active span.btnanimation:nth-child(2) {
    animation: ease 0.7s scaled forwards;
  }

  .not-active span.btnanimation:nth-child(2) {
    animation: ease 0.7s scaled-2 forwards;
  }

  .active span.btnanimation:nth-child(3) {
    animation: ease 0.7s bottom forwards;
  }

  .not-active span.btnanimation:nth-child(3) {
    animation: ease 0.7s bottom-2 forwards;
  }

  @keyframes top {
    0% {
      top: 0;
      transform: rotate(0);
    }
    50% {
      top: 22px;
      transform: rotate(0);
    }
    100% {
      top: 22px;
      transform: rotate(45deg);
    }
  }

  @keyframes top-2 {
    0% {
      top: 22px;
      transform: rotate(45deg);
    }
    50% {
      top: 22px;
      transform: rotate(0deg);
    }
    100% {
      top: 0;
      transform: rotate(0deg);
    }
  }

  @keyframes bottom {
    0% {
      bottom: 0;
      transform: rotate(0);
    }
    50% {
      bottom: 22px;
      transform: rotate(0);
    }
    100% {
      bottom: 22px;
      transform: rotate(135deg);
    }
  }

  @keyframes bottom-2 {
    0% {
      bottom: 22px;
      transform: rotate(135deg);
    }
    50% {
      bottom: 22px;
      transform: rotate(0);
    }
    100% {
      bottom: 0;
      transform: rotate(0);
    }
  }

  @keyframes scaled {
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes scaled-2 {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    position: absolute;
    top: 0vh;
    left: -100%;
    opacity: 1;
    margin-left: 0px;
  }

  .nav-menu.focus {
    background: rgb(70, 15, 15);
  }

  .nav-menu.active {
    background: rgb(18, 17, 17);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    z-index: 1;
    padding: 10px;
    align-items: center;
  }

  .nav-item {
    border: none;
    width: 100%;
    height: 65px;
    margin-bottom: 2em;
    margin-right: 0px;
    font-size: 18px;
  }

  
  .nav-item:before {
    border: none;
    background-color: #242222;
    content: "";
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: flex;
    position: absolute;
    right: 10px;
    font-size: 2rem;
    cursor: pointer;
  }

  .last-nav-links {
    background: transparent;
    color: #ffffff;
    height: 100%;
    width: 100%;
  }

  .last-item {
    justify-self: end;
    background: #b01b40;
    transition-duration: 0.3s;
    width: 290px;
    border-radius: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }


  .menu-icon .cross:hover {
    transform-origin: center;
    animation: rotate 0.5s linear;
  }

  @keyframes rotate {
    0% {
      transform: rotate(-180deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes antirotate {
    0% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

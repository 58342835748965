.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: #b01b40;
  color: white;
  text-align: center;
}

.contain {
  display: flex;
  height: inherit;
  align-items: start;
  margin-top: 1em;
}

.paypalbadges {
  position: relative;
  top: 40px;
}
.footer_icons {
  margin-top:3%;
  display: flex;
  width: 15%;
  justify-content: space-between;
}

.footer_icon {
  border-radius:50%;
  padding: 15px;
}

.footer_pages {
  display:flex;
  margin-top: 1%;
  color: grey;
}

.footer_page {
  margin-right: 10px;
  color: grey;
}

.footer_page > a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}


.footer_title {
  margin-top:1%;

}

.footer .col {
  width: 190px;
  height: auto;
  float: left;
  text-align: start;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0px 20px 20px 20px;
  text-decoration: none !important;
}

.footer .col h1 {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 17px;
  padding: 20px 0px 5px 0px;
  font-weight: normal;
  letter-spacing: 0.150em;
}
.footer .col ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer .col ul li {
  color: #999999;
  font-size: 14px;
  font-family: inherit;
  font-weight: bold;
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  transition: .2s;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  text-decoration: none !important;
}

.footer .col ul li a {
  color: #999999;
  font-size: 14px;
  font-family: inherit;
  font-weight: bold;
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  transition: .2s;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  text-decoration: none !important;
}

.social ul li {
  display: inline-block;
  padding-right: 5px !important;
}

.footer .col ul li:hover {
  color: #ffffff;
  transition: .1s;
  -webkit-transition: .1s;
  -moz-transition: .1s;
}

.footer .col ul li a:hover {
  color: #ffffff;
  transition: .1s;
  -webkit-transition: .1s;
  -moz-transition: .1s;
}

.clearfix {
  clear: both;
}
@media only screen and (min-width: 1280px) {
  .contain {
    width: 1200px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1139px) {
  .contain .social {
    width: 1000px;
    display: block;
  }
  .social h1 {
    margin: 0px;
  }
}
@media only screen and (max-width: 950px) {
  .footer .col {
    width: 25%;
  }
  .footer .col h1 {
    font-size: 14px;
  }
  .footer .col ul li {
    font-size: 13px;
  }
}
@media only screen and (max-width: 650px) {
    .footer .col {
      width: 33%;
    }
    .footer .col h1 {
      font-size: 14px;
    }
    .footer .col ul li {
      font-size: 13px;
    }
    .footer .image {
      transform: scale(0.75);
    }
}
@media only screen and (max-width: 340px) {
  .footer .col {
    width: 100%;
  }
}
.page {
  display: block;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.bn {
  filter: grayscale(1);
}
.page canvas {
  width: 75% !important;
  height: 100% !important;
  border: 2px solid rgb(239 232 232);
}

.headtitle {
  font-size: 13px;
  text-align: center;
}
.tesisection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #D1D1D1;
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.frontespiziosection {
  margin:30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Firstpagecontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.contdoc {
  display: block;
  justify-content: start;
}

.contdoc2 {
  display: flex;
  justify-content: space-between;
}

.document1 {
  display: block;
  width: 100%;
}

.document1.inactive {
  display: none;
}

.rowdocument {
  height: 50%;
}

.inputdiv {
  display: none;
}

.btnremove {
  border: none;
  background: transparent;
}

.coltesi {
  display: flex;
  flex-direction: column;
}

.colfrontespizio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.colfrontespiziopage.inactive {
  display: none;
}
.divbutton {
  display: flex;
  align-self: end;
  margin-top: 2%;
}

.alertmessage {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.iconcircle {
  margin-left: 1em;
}
.pointer {
  cursor: pointer;
}

.divinput {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.divinput.inactive {
  display: none;
}

.divquestion {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.divquestion.inactive {
  display: none;
}

.abled {
  background-color: white;
  border-color: #b01b40;
}

.abled:hover {
  color: #b01b40 !important;
  cursor: pointer;
}

.button.abled:hover .linkactive {
  color: #b01b40 !important;
}

.inviodisabled {
  background-color: lightgrey;
  border-color: lightgrey;
  cursor: not-allowed;
  pointer-events: none;
}

.linkactive {
  position: relative;
  text-decoration: none;
  color: white;
}

.linkactive:hover {
  color: white;
}

.linksecondactive {
  text-decoration: none;
  color: inherit;
}
.linksecondactive:hover {
  color: inherit;
}

.linkdisabled {
  position: relative;
  pointer-events: none;
  text-decoration: none;
  color: grey;
}


@media screen and (max-width: 375px) {
  .rowdocument {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 20px;
  }

  .divinput {
    flex-wrap: wrap;
  }

  .tesisection {
    margin-right: 20px;
    margin-left: 20px;
  }

  .divfronteretro {
    margin-left: 20px;
    margin-right: 20px;
  }
}

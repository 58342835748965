.typeorder {
  padding: 5px;
}

.colonna_ordine {
  display: grid;
  width: 45%;
  flex-direction: row;
  flex-wrap: wrap;
  grid-template-columns: auto auto;
}

.ordineclass {
  position: relative;
  padding: 0px !important;
  width: 30%;
}

.riepilogo .imganteprima {
  width: 100%;
  height: 100%;
}

.riepilogo .imgscritta {
  height: 100%;
  width: 100%;
  transform: scale(0.55);
}

@media only screen and (max-width: 480px) {

  .colonna_ordine {
    grid-template-columns: auto;
  }

  .ordineclass {
      position: relative;
      padding: 0px;
      width: 50%;
      left: -5%;
    }

    .riepilogo .imganteprima {
      width: 100%;
      height: 220px
    }
    
    .riepilogo .imgscritta {
      height: 300px;
      width: 100%;
      top: -40px;
      transform: scale(0.55);
    }
  }

* {
  font-family: "Lato", sans-serif;
}

:root {
  --form-control-color: #b01b40;
  --primary-color: #b01b40;
  --hover-button-color: #8e1634;
  --secondary-color: #EDEDED;
  --secondary-color-hover: #b4b4b4;

}

.App {
  display: flex;
  height: 100vh;
  flex-direction: column;
  width: 100vw;
  background-color: #ffffff;
}

.Appcontent {
  display: flex;
  overflow: scroll;
  max-width: 100%;
  max-height: 100vh;
  background-size: contain;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background: rgba(249, 247, 247, 1);
}

.descriptioneco {
  display: flex;
  position: relative;
  align-self: center;
  min-width: 300px;
  min-height: 300px;
  max-width: 400px;
  max-height: 400px;
  flex-direction: column;
}

.tipocarta {
  width: 50%;
  display: flex;
  justify-content: space-around;
  min-width: 0px;
}

/* fontsize */

.text-s {
  font-size: 14px;
}

.text-m {
  font-size:16px;
}

.text-l {
  font-size:18px;
}

.text-subtitle {
  font-size: 23px;
  font-weight: 500;
}


.text-xl {
  font-size: 30px;
}

.text-36 {
  font-size: 36px;
}

.text-xxl {
  font-size: 50px;
}

.fw-600 {
  font-weight: 600;
}

.title-medium {
  font-size: 17px;
  font-weight: bold;
}

.title-large {
  font-size: 20px;
  font-weight: bold;
}

.small-line-height {
  line-height:10px;
}

.primary {
  color: var(--primary-color);
}

.resizable {
  font-size: 1.5vw;
  
}

.paragraph {
  font-size: 16px;
}
.small {
  font-size: 9px;
}


/* font-weight */

.semibold {
  font-weight: 600;
}

.center {
  text-align: center;
}
.grey {
  background-color: #E5E5E5;
}
.flexible {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex-direction: column;
}

.flexcenter {
  align-items: center;
  justify-content: center;
}

/* margin */
.mt-s {
  margin-top: 2px;
}

.mt-m {
  margin-top: 6px;
}

.mt-l {
  margin-top: 12px;
}

.mt-xl {
  margin-top: 18px;
}

.mt-xxl {
  margin-top: 25px;
}

.mb-xs {
  margin-bottom: 0.5em;
}

.mb-s {
  margin-bottom: 1em;
}

.mb-m {
  margin-bottom: 5em;
}

.mb-l {
  margin-bottom: 10em;
}

.mb-xl {
  margin-bottom: 20em;
}

.mb-xxl {
  margin-bottom: 50em;
}

.ml-s {
  margin-left: 2px;
}

.ml-m {
  margin-left: 6px;
}

.ml-l {
  margin-left: 12px;
}

.ml-xl {
  margin-left: 18px;
}

.ml-xxl {
  margin-left: 25px;
}
.mr-s {
  margin-right: 2px;
}

.mr-m {
  margin-right: 6px;
}

.mr-l {
  margin-right: 12px;
}

.mr-xl {
  margin-right: 18px;
}

.mr-xxl {
  margin-right: 25px;
}

.ml-none {
  margin-left: 0% !important;
}

.mr-none {
  margin-right: 0% !important;
}

.mt-none {
  margin-top: 0% !important;
}

.mb-none {
  margin-bottom: 0% !important;
}


.m-80 {
  margin: 80px;
}

/* min-width */

.mw-s {
  min-width: 80px;
} 

.mw-m {
  min-width: 100px;
}

.mw-l {
  min-width: 120px;
}

.mw-xl {
  min-width: 160px;
}

.mw-xxl {
  min-width: 200px;
}

/* max-width */

.maw-s {
  max-width: 10%;
} 

.maw-m {
  max-width: 25%;
}

.maw-l {
  max-width: 50%;
}

.maw-xl {
  max-width: 75%;
}

.maw-xxl {
  max-width: 100%;
}

/* width */

.w-10{
  width: 10%;
}
.w-20{
  width: 20%;  
}
.w-30{
  width: 30%;  
}
.w-40{
  width: 40%;
}
.w-50{
  width: 50%;  
}
.w-60{
  width: 60%;  
}
.w-70{
  width: 70%;  
}
.w-80{
  width: 80%;  
}
.w-90{
  width: 90%;  
}
.w-100{
  width: 100%;  
}

.w-auto {
  width: auto;
}

/* height */

.h-10{
  height: 10%;
}
.h-20{
  height: 20%;  
}
.h-30{
  height: 30%;  
}
.h-40{
  height: 40%;
}
.h-50{
  height: 50%;  
}
.h-60{
  height: 60%;  
}
.h-70{
  height: 70%;  
}
.h-80{
  height: 80%;  
}
.h-90{
  height: 90%;  
}
.h-100{
  height: 100%;  
}

/* padding */

.p-m{
  padding: 1em
}

.pb {
  padding-bottom: 1em;
}

/* flex */
.jc-center {
  justify-content: center;
}

/* bottoni */

.button {
  display: flex;
  padding: 8px;
  background-color: white;
  color: black;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
  align-self: end;
  margin-right: 5px;
  align-items: center;
  justify-content: space-around;
  font-size: 15px;
}

.btninput:hover {
  cursor: pointer;
  background-color: var(--hover-button-color);
  color: white;
}

.btninput {
  display: flex;
  border: 0px solid whitesmoke;
  background: #b01b40;
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
  align-items: center;
  justify-content: space-evenly;
  font-size: 15px;
}

.btnsecondary {
  display: flex;
  font-size: 15px;
  border: 1px solid whitesmoke;
  background: #fdfdfd;
  color: #b01b40;
  text-decoration: none;
  font-weight: bold;
  padding: 10px;
  width: auto;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  justify-content: space-evenly;
}

.btnsecondary:hover {
  background: #f0f0f0;
}

.button:hover {
  background-color: #9d9a9a;
  cursor: pointer;
  color: black;
}

.notextdec {
  text-decoration: none;
}


@media only screen and (max-width: 480px) {

  .flexmobile {
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: 15%;
    flex-direction: column;
    font-size: 2vh;
  }

  .hidemobile {
    display: none !important;
  }

  .w-40 {
    width: 100%;
  }

  .contain {
    flex-wrap: wrap;
  }

  .footer {
    height: auto !important;
  }

  .footer .col {
    width: 10% !important;
  }

  .flexible {
    flex-wrap: wrap;
  }


  .btnsecondary {
    margin: 0;
  }

  .btninput {
    margin: 0;
  }

  .text-m {
    font-size:12px
  }

  .text-l {
    font-size:15px
  }

  .item-active {
    font-size: 10px !important;
  }

  .item-inactive {
    font-size: 10px !important;
  }

}

.containerstampa {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(249, 247, 247, 1);

}

.containerrow {
  color: black;
  display: flex;
  height: fit-content;
  font-size: 12px;
  margin-left: 60px;
  margin-top:5%;
  margin-bottom: 20px
}

.containerconfig {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px !important;
  background-color: white;
  box-shadow: 0px 4.4049248695373535px 22.02462387084961px 0px rgba(0, 0, 0, 0.07);
  height: fit-content;
  min-width: 435px;
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.cont_materiali {
  display: flex;
  margin-left: 4em;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  font-size: 12px !important;
  background-color: white;
  box-shadow: 0px 4.4049248695373535px 22.02462387084961px 0px rgba(0, 0, 0, 0.07);
  height: fit-content;
  margin-right: 4em;
  margin-bottom: 5%;
}

.containerconfigflex {
  font-size: 12px !important;
  height: fit-content;
  margin-bottom: 8%;
  margin-top: 1.5rem !important;
  width: 60% !important;
  margin-left: 4em;
}

.containerconfig2 {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 70vw;
  box-shadow: 0px 4.4049248695373535px 22.02462387084961px 0px rgba(0, 0, 0, 0.07);
  height: fit-content;
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.el1 {
  width: 18vw;
}

.el2 {
  width: 45vw;
  color: rgb(0, 0, 0);
}
.rilegature {
  width: 60vw;
}
.upload {
  width: 64vw;
}

.el3 {
  display: flex;
  width: 15vw;
}

.el4 {
  width: 18vw;
}

@media only screen and (max-width: 480px) {

  .el1 {
    width: 100%;
  }

.contsel {
  width: auto;
  flex-direction: row;
}
.containerrow {
  margin-right: 30px;
  margin-left: 30px;
  flex-wrap: wrap;
}

.totcont {
  font-size:14px;
  width: auto;
}
.el4 {
  position: relative;
  width:80%;
  margin-left: 2em;
  margin-top: 2em;
  left: 2em;
}
.upload {
  width: 75vw;
}

.rilegature {
  width:100%;
}

.containerconfigflex {
  width: 80% !important;
  margin-left: 0px;
}

.selmenu {
  width: 100%;
}

.el2 {
  width: 100%;
}

.Firstpagecontainer {
  width: 100%;
}

.containerconfig {
  width: 100%;
}
}

.main-container {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 100vw;
  height: 100% !important;
  font-weight: 600;
}

.home-color {
background: rgba(255, 255, 255, 0.9) !important;

}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.homepage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./homepage.jpg");
  background-size: cover;
}

.homebox-container {
  margin-left: 200px;
  margin-right: 200px;
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: space-around;
}
.special {
  align-items: center;
  justify-content: space-around;
}
.homebox {
  display: flex;
  overflow: hidden;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 20px;
  min-height: 200px;
  min-width: 200px;
  text-align: center;
}

/* .homeboximg {
  width: 80px;
  height: 60px;
  filter: invert(16%) sepia(75%) saturate(4304%) hue-rotate(334deg)
    brightness(76%) contrast(89%);
  margin-bottom: 2em;
  margin-top: 2em;
  display: flex;
  align-items: center;
  justify-content:center;
  
} */

.homeboximg {
  display: flex;
width: 272px;
flex-direction: column;
align-items: center;
gap: 12px;
}

.box1 {
  transition: transform 0.3s ease-in-out;
}

.box1:hover {
  transform: scale(1.2);
}

.box2 {
  transition: transform 0.7s ease-in-out;
}

.box2:hover {
  transform: rotate(360deg);
}

.box3 {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0px;
}

.box3:hover {
  animation: shift 3s ease-in-out;
}

.btncontainer {
  align-self: center;
  padding: 60px;
}

.fascia {
  height: 380px;
  margin-top: 6%;
  margin-bottom: 6%;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cont-text-fascia {
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cont_button {
  width: 232px
}

.text-fascia {
  display: flex;
width: 595px;
flex-direction: column;
flex-shrink: 0;
}



.altriServizi {
  height: 90%;
}

@keyframes shift {
  0% {
    left: 0px;
  }
  50% {
    left: 160px;
  }
  100% {
    left: -160px;
  }
}

.divstartbutton {
  display: flex;
  justify-content: center;
}
.startbutton {
  display: flex;
  color: #fff;
  border: 3px solid #fff;
  border-radius: 10px;
  align-items: center;
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  width: 250px;
  height: 75px;
  padding: 0.5rem 1rem;
  justify-content: center;
  outline: none;
}

.startbutton:hover {
  color: #000000;
  background-color: #fff;
  transition: all 0.2s ease-in;
}

.row1 {
  min-height: 10%;
  height: 10%;
}

.row2 {
  display: flex;
  height: 80%;
  align-items: center;
  justify-content: flex-start;
}

.specchietto {
  display: flex;
  background-color: rgb(135, 95, 60);
  width: 800px;
  height: 600px;
  border: 3px solid rgb(255, 255, 255);
  flex-direction: column;
  justify-content: space-between;
  margin-left: 50px;
  transition: box-shadow 0.8s ease 0.15s;
}

.buttonservice {
  color: #fff;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  justify-content: center;
  outline: none;
}

.buttonservice:hover {
  color: #000000;
  transition: all 0.2s ease-in;
  background: linear-gradient(to right, #000000 0, #900048 10%, #000000 20%);
  background-position: 0;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
}

.showmobile {
  display: none !important;
}

.materiali.imganteprima {
  height:500px;
  width: 400px;
}

.materiali.imgscritta {
  height:500px;
  width: 400px;
}
.cropper {
  margin-bottom: 4em;
  margin-left: 36px;
  padding: 2em;
 }

 .jqhQCP > div {
  overflow: auto !important;
 }

 .fascia .imgcopertina {
height: 440px;
 }

.imgcopertina {
  background-image: url("./copertina.png");
  height: 110%;
  width: 30%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.titoli {
 width: 58%;
 }

.lavori {
  height: 396px;
  width: 424px;
  overflow: hidden;
}


.img-responsive {
  position: relative;
  left: -145px;
  top: -39px;
}

.imgservizi {
  background-image: url("./servizi.jpg");
  height: 442px;
  width: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 90px;
  }
  100% {
    background-position: 140px;
  }
}

@media only screen and (max-width: 768px) {
  .specchietto {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    height: 60%;
  }

  .bottoni {
    font-size: x-small;
  }

  .homebox {
    margin: 10%;
    width: 30vw;
    min-height: 20vh;
  }

  .homebox-container {
    flex-wrap: wrap;
  }

}


@media only screen and (max-width: 480px) {
  .showmobile {
    display: flex !important;
  }

  .hidemobile {
    display: none !important;
  }

  .homebox-container {
    margin-top: 70px;
  }

  .imgservizi {
    height:200px;
    width: 100%;
}

  .homebox .text-subtitle {
    font-size: 15px;
  }

  .homebox .text-s {
    font-size:12px;
  }

  .homepage {
    justify-content: center;
  }

  .maw-l {
    max-width: 75%;
  }

  .text-xxl {
    font-size: 4vh;
  }

  .text-xl {
    font-size: 3vh;
  }

  .titoli {
    width: 70%;
    margin-bottom: 3em;
  }

}

.Secondpagecontainer {
  display: flex;
  background-color:white;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  padding: 20px;
}

.divcontrilegature .containerconfig {
  width: 59vw;
}

.container1{
  display: flex;
  height: "40%"; 
  min-height: 270px;
  flex-wrap: wrap;
}

.divfronteretro {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 30px;
  border-bottom: 1px solid #D1D1D1;
}

.shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.selrilegature {
  font-size: 15px;
}

.divselect {
  width: 70%;
}

.divcopertinemobile {
  position: relative;
  width: 100%;
}

.divcopertinemobile .miniatura {
height: 160px;
left: 0px;
width: 215px;
position: relative;
}

.divcopertinemobile .scrittaminiatura {
  position: absolute;
  height: 150px;
  width: 300px;
  top: 0px;
  left: -45px;
  transform: scale(0.7);
}

.inputradio {
  display: flex;
  align-items: baseline;
  padding: 2px;
  width: max-content;
}

.labelradio {
  margin-left: 0.5rem;
  font-size: 14px;
}
.tipomateriale {
  display: flex;
}
.formselect {
  display: block;
  width: 223px;
  height: 28px;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px 12px;
  background-position: right 0.75rem center;
  border: 1px solid #ced4da;
  border-radius: 8.8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
.itemtitle {
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 18px;
  padding: 0px;
}

.rilegature-item {
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border: 0.1px solid #D1D1D1;
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 18px;
  justify-content: space-between;
  align-items: flex-start;
}

.coloremateriale {
  flex-direction: column;
  align-items: baseline;
}

.divcoloricopertina {
  display: grid;
  grid-template-columns: repeat(auto-fill, 75px);
  grid-gap: 4px;
  width: 100%;
}

.divcolorifrontespizio {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 75px);
  grid-gap: 5px;

}

.divcolorecopertina {
  display: flex;
  flex-direction: column;
  align-self: center;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  margin-right: auto;
  padding: 10px;
  height: 70px;
  width: 70px;
}

.colorecopertina {
  margin-bottom: 5%;
}



.divrilegatura {
  justify-content: flex-start;
  width: fit-content;
  border-radius: 10px;
  display: flex;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 8px 1px;
}

.divcopertina {
  position: relative;
  justify-content: center;
  width: 40%;
  transition: all .2s ease-in-out;
}

.itemrilegatura {
  background: #d9d9d900;
  padding: 5px;
  width: 80px;
  cursor: pointer;
  text-align: center;
  display: flex;
  height:45px;
  align-items: center;
  justify-content:center;
  flex-direction: column;
  height: auto;
}

.itemrilegatura.rigida {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.itemrilegatura.cartoncino {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.itemrilegatura.active {
  background-color: #b01b40;
  color: white;
  font-weight: bold;
}
.imgcolori {
  height: 30px;
  width: 30px;
  border: 1px solid black;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.imgcolori:hover {
  transform: scale(1.5);
}
.selected {
  border: 2px solid red;
}
.tablecopie {
  display: table-row;
  font-size: larger;
  font-weight: bold;
}
.coltable {
  display: table-cell;
  padding: 10px;
}

.copie {
  display: table-row;
}

.button.copie:hover {
  background-color: #b01b40;
  color: white;
}

.numerocopie {
  justify-content: end;
  border: none;
  padding-top: 40px;
}

.materiali .imganteprima {
  height: 760px;
}

.materiali .imgscritta {
  height: 780px;
  transform: scale(0.8);
  left: 126px;
}

.rilegature .imgscritta {
  height: 555px;
  min-width: 200px;
  transform: scale(0.8);
  left: 33px;
}

.rilegature .imganteprima {
  height: 555px;
  min-width: 200px;
  left: -63px;
}


.imganteprima {
  position: relative;
}

.imgscritta {
  position: absolute;
  top: 0;
  left: 0;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor:zoom-in;
  z-index: 1;
  /*Set the size of the magnifier glass:*/
  width: 200px;
  height: 200px;
}

.el3.anteprima {
  display: flex;
  flex-direction: column;
}

.el4.totale {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.el4.pagamento {
  width: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.alertcopy {
  position: absolute;
  display: block;
  background: grey;
  color: #fff;
  font-size: 1vw;
  margin-top: 2%;
  margin-bottom: 1rem;
  right: 0px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 10px;
}

.minus,
.plus {
  width: 18px;
  height: 20px;
  color: #b01b40;
  background: #f2f2f2;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.contbuttonandalert {
  position: relative;
  width: 100%;
  height: 12%;          
}

.disabled {
  background-color: lightgrey;
  border-color: lightgrey;
  cursor: not-allowed !important;
}

.button.disabled:hover ~ .alertcopy {
  display: block;
}

.circlebuttons {
  border: 1px solid;
  border-radius: 50%;
}

.arrowRight {
  position: absolute;
  left: 65%;
  display: flex;
}

.selections {
  display: flex;
  flex-direction: column;
  width: 55%;
  justify-content: space-around;
  min-width: 200px;
}

.cont_nostri_materiali {
  display:flex;
  height: 40%;
  min-height: 270px;
}

.cont_nostri_materiali2 {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-around;
}


@media only screen and (max-width: 755px) {

  .divselect {
    width: 100%;
  }

  .cont_nostri_materiali {
    flex-wrap: wrap;
  }
  .cont_nostri_materiali2 {
    width: 100%;
  }

}



@media only screen and (max-width: 480px) {

.Secondpagecontainer {
  padding: 0px;
}

.divcontrilegature {
  width: 100%;
}

.divcontrilegature .containerconfig {
  width: 100%;
}

.containerconfig2 {
  width: 100%;
}

.divfronteretro {
  justify-content: flex-start;
}

.divcoloricopertina {
  grid-template-columns: repeat(auto-fill, 55px);
}

.divcolorifrontespizio {
  grid-template-columns: repeat(auto-fill, 55px);
}

.divselect {
  width: 100%;
}

.divcopertina {
  width: 100%;
  height: 400px;
  margin-bottom: 4em;
}

.container1 {
  flex-wrap: wrap;
}

.selections {
  width: 100%;
}

.copiemobile {
  display: flex;
  flex-direction: column;
}

.itemrilegatura {
  width:25px !important;
}

.contril {
  position: relative;
  width: 50%;
}

.imgscritta {
  min-width: 100%;
}

.imganteprima {
  min-width: 100%;
}

.title-large {
  font-size: 20px !important;
}

.btninput {
  font-size: 13px !important;
}

.totprice {
  font-size: 15px !important
}

.divCAP {
  width: 100% !important;
}

.provincia {
  width: 100% !important;
}

.città {
  width: 100% !important;
}

.riepilogo {
  flex-wrap: wrap;
}

.containerconfig {
  min-width: auto !important;
}

.rilegature-item {
  margin-left: 20px;
  margin-right: 20px;
}

._imgprova {
  height: 200px;
  width: 100%;
}

.rilegature .imgscritta {
  height: 431px;
  left: 20px;
}

.rilegature .imganteprima {
  height: 435px;
}

.contril .imgscritta {
  height: 313px;
  left: -63px;
  top: -48px;
  transform: scale(0.5);
}

.contril .imganteprima {
  height:213px;
  left: -63px;
}

.materiali .imganteprima {
  position: absolute;
  width: 100%;
  top: 0px;
  height:385px;
}

.materiali .imgscritta {
  height: 457px;
  width: 100%;
  left: 0px;
  top: -41px;
  transform: scale(0.55);
}

.cont_nostri_materiali {
  flex-wrap: wrap;
}

.cont_nostri_materiali2 {
  display: flex;
  width: 100%;
}

}